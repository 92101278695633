import Image from "next/image";

import styles from "./ImageIcon.module.scss";

const ImageIcon = ({ src, priority }: { src: string; priority?: boolean }) => {
  return (
    <i className={styles.icon} data-testid="image-icon">
      <Image src={src} alt="" width={16} height={16} priority={priority} />
    </i>
  );
};

export { ImageIcon };
