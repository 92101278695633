const getBrandedAboutOmniLinks = () => {
  const isOmniEkonomi = process.env.NEXT_PUBLIC_UI_THEME === "ekonomi";

  const omniCoreConfidentialityURL =
    "https://privacysettings.schibsted.com/?client_id=52454d279eaf7ced5d000000&utm_source=omni&utm_medium=menu";
  const omniEkonomiConfidentialityURL =
    "https://privacysettings.schibsted.com/?client_id=52454d279eaf7ced5d000000&utm_source=omni-ekonomi&utm_medium=menu";

  return {
    contact: "https://support.omni.se/s/contactsupport",
    customerSupport: "https://support.omni.se",
    publisher: "https://om.omni.se/link/omni/ansvarig-utgivare",
    privacyPolicy:
      "https://info.privacy.schibsted.com/se/schibsted-sverige-personuppgiftspolicy",
    confidentialitySettings: isOmniEkonomi
      ? omniEkonomiConfidentialityURL
      : omniCoreConfidentialityURL,
  };
};

export { getBrandedAboutOmniLinks };
