"use client";

import { Form as AriaForm, useFormStore, FormInput } from "@ariakit/react";
import cn from "classnames";

import styles from "./SearchForm.module.scss";
import { Button } from "../Button/Button";
import { SearchFormLabel } from "./SearchFormLabel";

export interface SearchFormProps {
  onSubmit: (search?: string) => void;
  initialValue?: string;
  showLabel?: boolean;
  background?: boolean;
}

export function SearchForm({
  onSubmit,
  initialValue,
  showLabel,
  background,
}: SearchFormProps) {
  const formStore = useFormStore({
    defaultValues: { search: initialValue },
  });

  formStore.useSubmit((event) => {
    onSubmit(event.values.search);
  });

  return (
    <AriaForm
      className={styles.groupContainer}
      store={formStore}
      resetOnSubmit={false}
    >
      <SearchFormLabel
        showLabel={showLabel}
        name={formStore.names.search}
        text="Sök efter artiklar och ämnen"
      />
      <div
        className={cn(
          background
            ? [styles.inputFieldContainer, styles.showBackground]
            : [styles.inputFieldContainer],
        )}
      >
        <FormInput
          className={styles.inputField}
          id="search-input"
          type="text"
          placeholder="Ange t.ex ord i rubriken"
          aria-label="Search field"
          name={formStore.names.search}
        />
        <div className={styles.submitFieldContainer}>
          <Button
            text="Sök"
            size="large"
            variant="primary"
            slug="search"
            type="submit"
          />
        </div>
      </div>
    </AriaForm>
  );
}
