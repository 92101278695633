import cn from "classnames";
import Image from "next/image";
import Link from "next/link";

import styles from "./MenuItem.module.scss";
import { ICON_MAP, Icon } from "../../../Icon/Icon";
import { ImageIcon } from "../../../ImageIcon/ImageIcon";

interface MenuItem {
  icon?: string;
  text: string;
  showSchibstedLogo?: boolean;
  isExternalLink?: boolean;
  href?: string;
  onClick?: () => void;
  type: "link" | "button";
  isPremium?: boolean;
}

const MenuItem = ({
  icon,
  text,
  showSchibstedLogo = false,
  isExternalLink = false,
  href,
  onClick,
  type,
  isPremium = false,
}: MenuItem) => {
  const isLink = type === "link" && !!href;
  const isButton = type === "button" && !!onClick;
  const hasImageIcon = icon && !(icon in ICON_MAP);
  const itemContent = (
    <>
      {hasImageIcon ? (
        <ImageIcon src={icon} />
      ) : icon ? (
        <Icon variant={icon} />
      ) : null}
      <span>{text}</span>
      <span className={styles.itemNav}>
        {showSchibstedLogo && (
          <Image
            src={`${process.env.NEXT_PUBLIC_ASSET_PREFIX || ""}/images/shibsted-logo.png`}
            width={61}
            height={12}
            alt="Schibsted"
          />
        )}
        <Icon variant="caretRight" className={styles.caretIcon} />
      </span>
    </>
  );

  return (
    <li role="presentation">
      {isLink && isExternalLink ? (
        <a
          href={href}
          className={cn([styles.menuItem, isPremium && styles.premium])}
          role="menuitem"
          target="_blank"
        >
          {itemContent}
        </a>
      ) : isLink ? (
        <Link
          href={href}
          className={cn([styles.menuItem, isPremium && styles.premium])}
          role="menuitem"
          prefetch={false}
          onClick={onClick}
        >
          {itemContent}
        </Link>
      ) : isButton ? (
        <button
          type="button"
          onClick={onClick}
          aria-label={text}
          className={cn([
            styles.menuItem,
            isPremium && styles.premium,
            styles.button,
          ])}
          role="menuitem"
        >
          {itemContent}
        </button>
      ) : null}
    </li>
  );
};

export { MenuItem };
