"use client";
import style from "./MenuSearch.module.scss";
import { Search } from "../../../Discover/Search";

const MenuSearch = () => {
  return (
    <div className={style.menuSearchContainer}>
      <Search showLabel background hardLink />
    </div>
  );
};

export { MenuSearch };
