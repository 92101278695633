"use client";

import { useEffect, useState } from "react";
import { usePathname, useSearchParams } from "next/navigation";

import type { CategoriesResponse } from "../../../types/content";
import type { SpidSettings } from "../../../types/settings";

import styles from "./HeaderActions.module.scss";
import { Menu } from "../Menu/Menu";
import { Button } from "../../Button/Button";
import { StartpageSwitcher } from "./StartPageSwitcher/StartPageSwitcher";
import { usePulse } from "../../../pulse/usePulse";

const HeaderActions = ({
  categoryData,
  isLoggedIn,
  isPremium,
  userSettings,
}: {
  categoryData?: CategoriesResponse;
  isLoggedIn: boolean;
  isPremium: boolean;
  userSettings?: SpidSettings;
}) => {
  const isEconomy = process.env.NEXT_PUBLIC_UI_THEME === "ekonomi";
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const pathName = usePathname();
  const searchParams = useSearchParams();
  const { trackOpenMenu } = usePulse();

  useEffect(() => {
    if (isMenuOpen) setIsMenuOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName, searchParams]);

  const onClick = () => {
    if (!isMenuOpen) {
      trackOpenMenu();
    }
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      {isMenuOpen ? (
        <Menu
          categoryData={categoryData}
          isLoggedIn={isLoggedIn}
          isPremium={isPremium}
          userSettings={userSettings}
          onClick={onClick}
        />
      ) : (
        <div className={styles.navigation}>
          <StartpageSwitcher />
        </div>
      )}
      <div className={styles.menuButtons}>
        {!isPremium && (
          <div className={styles.membershipButton}>
            <Button
              text={"Bli medlem"}
              size="small"
              variant="secondary"
              slug="membership"
              type="link"
              href={
                isEconomy
                  ? "https://om.omni.se/ekonomi?utm_source=header&utm_channel=ekonomi_web&utm_campaign=product_integrated&utm_content=button&utm_term=acquisition"
                  : "https://om.omni.se/omni-mer?utm_source=header&utm_channel=omni_web&utm_campaign=product_integrated&utm_content=button&utm_term=acquisition"
              }
              isExternalLink
            />
          </div>
        )}
        <div className={styles.menuToggleButton}>
          <Button
            hiddenText="meny"
            icon={isMenuOpen ? "close" : "menu"}
            size="compact"
            variant="primary"
            onClick={onClick}
            slug="more"
            id="menubutton"
            aria-haspopup="menu"
            aria-expanded={isMenuOpen}
            aria-controls="menu"
          />
        </div>
      </div>
    </>
  );
};

export { HeaderActions };
