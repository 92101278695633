"use client";

import Link from "next/link";
import cn from "classnames";

import styles from "./Logo.module.scss";

export const Logo = () => {
  const theme =
    (process.env.NEXT_PUBLIC_UI_THEME as "core" | "ekonomi") || "core";

  return (
    <Link
      href="/"
      className={cn([
        styles.headerLogo,
        theme === "core" ? styles.coreLogo : styles.economyLogo,
      ])}
      prefetch={false}
    >
      <span className={styles.hiddenText}>Hem</span>
    </Link>
  );
};
