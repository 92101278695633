import styles from "./MenuGroup.module.scss";
import { MenuItem } from "../MenuItem/MenuItem";

interface MenuGroup {
  title?: string;
  pill?: string;
  items: MenuItem[];
  footerContent?: JSX.Element | string;
}

const MenuGroup = ({ title, pill, items, footerContent }: MenuGroup) => {
  return (
    <>
      {items && (
        <div className={styles.groupContainer}>
          {title && (
            <div className={styles.groupTitle}>
              <span className={styles.groupTitleText}>{title}</span>
              {pill && <span className={styles.pill}>{pill}</span>}
            </div>
          )}
          <nav className={styles.groupItems} role="group" tabIndex={-1}>
            <ul className={styles.listContainer}>
              {items.map((item, index) => {
                return (
                  <MenuItem
                    icon={item.icon}
                    text={item.text}
                    href={item.href}
                    onClick={item.onClick}
                    isPremium={item.isPremium}
                    isExternalLink={item.isExternalLink}
                    showSchibstedLogo={item.showSchibstedLogo}
                    type={item.type}
                    key={`${item.text}${index}`}
                  />
                );
              })}
            </ul>
          </nav>
          {footerContent && (
            <div className={styles.footerInfo}>{footerContent}</div>
          )}
        </div>
      )}
    </>
  );
};

export { MenuGroup };
