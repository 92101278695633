const BRAND_SPECIFIC_TEXTS = {
  premiumName: {
    core: "Omni Mer",
    ekonomi: "Medlem",
  },
  productName: {
    core: "Omni",
    ekonomi: "Omni Ekonomi",
  },
  salesPosterTitle: {
    core: "Få ut ännu mer av Omni – skaffa Omni Mer",
    ekonomi: "Få full tillgång till Omni Ekonomi",
  },
  salesPosterUSP1: {
    core: "Förklaring & fördjupning",
    ekonomi: "Alla mediers affärsnyheter på ett ställe",
  },
  salesPosterUSP2: {
    core: "Gå förbi betalväggar",
    ekonomi: "Upplåst innehåll från världens ledande medier",
  },
  salesPosterUSP3: {
    core: "Annonsfri upplevelse",
    ekonomi: "Dagliga aktieanalyser direkt i appen",
  },
};

const theme =
  (process.env.NEXT_PUBLIC_UI_THEME as "core" | "ekonomi") || "core";

const getBrandSpecificText = (key: keyof typeof BRAND_SPECIFIC_TEXTS) => {
  return BRAND_SPECIFIC_TEXTS[key][theme];
};

export { getBrandSpecificText };
