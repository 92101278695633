import Image from "next/image";

import styles from "./MenuFooter.module.scss";
import { getBrandSpecificText } from "../../../../utils/getBrandSpecificText";

const MenuFooter = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.footerSection}>
        Omnipunktse AB, Blekholmsgatan 18, 111 64 Stockholm. Org.nr 556774-0864.{" "}
        {getBrandSpecificText("productName")} är en del av Schibsted Media.
        Schibsted Media ansvarar för hanteringen av din data på den här
        webbplatsen
      </div>
      <div>
        <Image
          src={`${process.env.NEXT_PUBLIC_ASSET_PREFIX || ""}/images/schibsted-media-logo.png`}
          width="100"
          height="13"
          alt="Schibsted"
          className={styles.footerImage}
        />
      </div>
    </div>
  );
};

export { MenuFooter };
