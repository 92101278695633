"use client";

import React from "react";
import Link from "next/link";
import cn from "classnames";
import { usePathname } from "next/navigation";

import styles from "./StartPageSwitcher.module.scss";

const StartpageSwitcher = () => {
  const pathname = usePathname();
  return (
    <div className={styles.container}>
      <Link
        href="/"
        className={cn([styles.link, pathname === "/" && styles.selected])}
        prefetch={true}
      >
        Toppnyheter
      </Link>
      <Link
        href="/senaste"
        className={cn([
          styles.link,
          pathname === "/senaste" && styles.selected,
        ])}
        prefetch={true}
      >
        Senaste nytt
      </Link>
    </div>
  );
};

export { StartpageSwitcher };
