import type { MenuItem } from "../../MenuItem/MenuItem";

import { triggerTCFSettingsModal } from "../../../../../tcf/helpers";
import { MenuGroup } from "../MenuGroup";
import { getBrandedAboutOmniLinks } from "./utils/getBrandedAboutOmniLinks";

const AboutOmniGroup = () => {
  const links = getBrandedAboutOmniLinks();
  const aboutOmniItems = [
    {
      text: "Tyck till om Omni",
      href: links.contact,
      isExternalLink: true,
      type: "link",
    },
    {
      text: "Hjälp & kontakt",
      href: links.customerSupport,
      isExternalLink: true,
      type: "link",
    },
    {
      text: "Ansvarig utgivare",
      href: links.publisher,
      isExternalLink: true,
      type: "link",
    },
    {
      text: "Personuppgiftspolicy",
      showSchibstedLogo: true,
      href: links.privacyPolicy,
      isExternalLink: true,
      type: "link",
    },
    {
      text: "Sekretessinställningar",
      showSchibstedLogo: true,
      href: links.confidentialitySettings,
      isExternalLink: true,
      type: "link",
    },
    {
      text: "Inställningar för cookies",
      showSchibstedLogo: true,
      type: "button",
      onClick: triggerTCFSettingsModal,
    },
  ] as MenuItem[];

  return (
    <MenuGroup title={"Om Omni / Din personliga data"} items={aboutOmniItems} />
  );
};

export { AboutOmniGroup };
