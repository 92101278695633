import { FormLabel } from "@ariakit/react";
import cn from "classnames";

import type { StringLike } from "@ariakit/core/form/types";

import styles from "./SearchFormLabel.module.scss";

export interface SearchFormLabelProps {
  showLabel?: boolean;
  name: StringLike;
  text: string;
}

export function SearchFormLabel({
  showLabel,
  name,
  text,
}: SearchFormLabelProps) {
  const classes = [styles.groupTitle];

  if (!showLabel) {
    classes.push(styles.hidden);
  }

  return (
    <FormLabel className={cn(classes)} name={name}>
      {text}
    </FormLabel>
  );
}
