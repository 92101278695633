const getLoginStateToken = async (redirectURL: string) => {
  try {
    const url = `${process.env.NEXT_PUBLIC_SETTINGS_API_BASE}/state?redirect_to=${encodeURIComponent(redirectURL)}`;
    const response = await fetch(url);
    const data = await response.json();

    if (!response.ok) {
      throw new Error(`API Error: ${data.message || "Failed to fetch data"}`);
    }

    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export { getLoginStateToken };
