import { getIdentityInstance } from "./getIdentityInstance";
import { getLoginStateToken } from "./getLoginStateToken";

const login = async (): Promise<void> => {
  try {
    const identityInstance = getIdentityInstance();
    if (identityInstance) {
      const currentHref = window.location.href;
      const stateToken = await getLoginStateToken(currentHref);
      identityInstance.login({ state: stateToken });
    }
  } catch (error) {
    console.error(`Error when login: ${error}`);
  }
};

export { login };
